import React from 'react'
import { graphql } from 'gatsby'
import Weekly from '../components/Weekly'

function WeeklyTemplate({ data: { weekly } }) {
  return <Weekly {...weekly}></Weekly>
}

export default WeeklyTemplate

export const pageQuery = graphql`
  query WeeklyQuery($issue: Int!) {
    weekly: weeklyYaml(issue: { eq: $issue }) {
      title
      publishedAt(locale: "th-TH", formatString: "DD MMM YYYY")
      publishedAtDate: publishedAt
      issue
      items {
        author
        category
        details
        title
        type
        url
      }
    }
  }
`
