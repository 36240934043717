import React, { useEffect } from 'react'
import styled from 'styled-components'

import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO'
import PageTitle from '@babelcoder/gatsby-theme-base/src/components/PageTitle'
import WeeklyItem from './WeeklyItem'
import useWeeklyPath from '../hooks/useWeeklyPath'
import useWeeklySchema from '../hooks/useWeeklySchema'
import useWeeklyCoverImage from '../hooks/useWeeklyCoverImage'

const Wrapper = styled.section`
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacers.large};
`

function Weekly(weekly) {
  const { issue, title, items } = weekly
  const { getWeeklyPath } = useWeeklyPath()
  const cover = useWeeklyCoverImage()
  const getSchema = useWeeklySchema()

  useEffect(() => {
    if(typeof fbq === 'function') {
      // eslint-disable-next-line no-undef
      fbq('track', 'ViewContent', {
        content_type: 'weekly',
        content_ids: [issue],
        content_name: title,
      });
    }
  }, [issue, title])

  return (
    <Wrapper>
      <SEO
        title={`ฉบับที่ ${issue}`}
        type="article"
        urlPath={getWeeklyPath(issue)}
        coverImageWidth={cover.fixed.width}
        coverImageHeight={cover.fixed.height}
        coverImageURL={cover.fixed.src}
        desc={title}
        schema={getSchema(weekly)}
      ></SEO>
      <PageTitle>ฉบับที่ {issue}</PageTitle>
      {items.map((item, index) => (
        <WeeklyItem key={index} {...item}></WeeklyItem>
      ))}
    </Wrapper>
  )
}

export default Weekly
