import React from 'react'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import {
  DiRuby,
  DiHtml5,
  DiPython,
  DiCss3,
  DiDocker,
  DiJavascript1,
  DiCode,
} from 'react-icons/di'

import MDXRenderer from '@babelcoder/gatsby-theme-base/src/components/mdx/Renderer'

const CATEGORY_ICONS = {
  JavaScript: {
    icon: DiJavascript1,
    color: '#f7df1e',
  },
  TypeScript: {
    icon: DiJavascript1,
    color: '#284E7F',
  },
  HTML: {
    icon: DiHtml5,
    color: '#005a9c',
  },
  CSS: {
    icon: DiCss3,
    color: '#ff9800',
  },
  Python: {
    icon: DiPython,
    color: '#366D9C',
  },
  Ruby: {
    icon: DiRuby,
    color: '#AA1400',
  },
  DevOps: {
    icon: DiDocker,
    color: '#027CFE',
  },
  default: {
    icon: DiCode,
    color: '#37BC9B',
  },
}

const TYPES = {
  article: 'บทความ',
  tool: 'เครื่องมือ',
}

const Wrapper = styled.article``

const HeaderTop = styled.div`
  display: flex;
`

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacers.normal};

  & > svg {
    font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  }
`

const MetaWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
    padding: ${theme.spacers.small};
    background-color: #a7e5d62b;

    * + * {
      margin-top: ${theme.spacers.small};
    }

    ${up('medium')} {
      flex-direction: row;

      * + * {
        margin-top: 0;
        margin-left: ${theme.spacers.normal};
        padding-left: ${theme.spacers.normal};
        border-left: 1px solid ${theme.colors.neutral.gray600};
      }
    }
  `}
`

const AuthorName = styled.div`
  color: ${({ theme }) => theme.colors.neutral.gray900};
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.main.primary};
  text-decoration: underline;
`

const MetaType = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.neutral.white};
    background-color: ${theme.colors.main.primary};
    padding: ${theme.spacers.xxsmall} ${theme.spacers.xsmall};
    border-radius: ${theme.round.xlarge};
  `}
`

const Devider = styled.div`
  ${({ theme }) => css`
    width: 100px;
    height: 2px;
    background-color: ${theme.colors.neutral.gray700};
    margin: ${theme.spacers.large} auto;
  `}
`

export const HeaderTitle = ({ category, title, titleAs: Title = 'h2' }) => {
  const { icon: Icon, color: iconColor } =
    CATEGORY_ICONS[category] || CATEGORY_ICONS['default']

  return (
    <HeaderTop>
      <IconWrapper>
        <Icon color={iconColor}></Icon>
      </IconWrapper>
      <Title>{title}</Title>
    </HeaderTop>
  )
}

function WeeklyItem({ author, category, details, title, type, url }) {
  return (
    <Wrapper>
      <header>
        <HeaderTitle category={category} title={title} />
        <MetaWrapper>
          <MetaType>{TYPES[type]}</MetaType>
          <AuthorName>{author}</AuthorName>
          <Link href={url}>แหล่งที่มา</Link>
        </MetaWrapper>
      </header>
      {details && <MDXRenderer>{details}</MDXRenderer>}
      <Devider></Devider>
    </Wrapper>
  )
}

export default WeeklyItem
